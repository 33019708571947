<template>
  <div class="tab-bar">
    <div v-for="(item, index) in tabsData" :key="index" class="btn" @click="handleClick(item)">
      <el-button :type="item.isActive ? 'primary' : ''"> {{ item.name }} </el-button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmit, defineExpose, watchEffect, ref } from 'vue'
const props = defineProps({
  data: {
    type: Object,
    default: [
      {
        name: '折扣',
      },
    ],
  },
  activeTab: {
    type: [Number, Array],
    default: 0,
  },
  //是否多选
  multiple: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmit(['clickTab'])

// 自己的tab数据
const tabsData = ref([])
/**监听夫组件变化，而变化 */
watchEffect(() => {
  tabsData.value = props.data.map((a, i) => {
    if (!Array.isArray(props.activeTab)) {
      return {
        ...a,
        isActive: props.activeTab ? (a.code === props.activeTab ? true : false) : i === 0 ? true : false,
      }
    } else {
      let isHas = props.activeTab.findIndex(isActive => isActive === a.code)
      return {
        ...a,
        isActive: isHas !== -1 ? true : false,
      }
    }
  })
})

/**
 * 点击事件
 * @param {object} newActive
 */
const handleClick = newActiveTab => {
  toggleActive(newActiveTab)
  if (!props.multiple) {
    emit('clickTab', newActiveTab)
  } else {
    emit(
      'clickTab',
      tabsData.value.filter(item => item.isActive).map(item => item.code),
    )
  }
}

/**
 * 切换活跃状态
 * @param {object} newActiveTab  新的tab
 * @returns
 */
const toggleActive = newActiveTab => {
  let oldActiveTabs = tabsData.value.filter(item => item.isActive)
  let isHasNew = tabsData.value.filter(item => item.isActive && newActiveTab.code === item.code).length > 0
  if (isHasNew && oldActiveTabs.length > 1) {
    newActiveTab.isActive = false
    return
  }

  if (!props.multiple) {
    oldActiveTabs[0].isActive = false
  }
  newActiveTab.isActive = true
}

defineExpose({
  tabsData,
  a: 10,
})
</script>

<style scoped lang="scss">
.tab-bar {
  display: flex;
  .btn {
    flex: 1;
    .el-button {
      width: 100%;
    }
  }
}
</style>
